<template>
  <div>
    <h2 class="tw-mt-2 tw-mb-8">
      <router-link
        :to="backRoute"
        title="Ga terug"
        class="!tw-text-tg-color hover:tw-opacity-80"
      >
        <i class="fal fa-arrow-left tw-mr-2" />
      </router-link>
      {{ displayRole }}
    </h2>
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      class="lg:tw-grid tw-grid-cols-2 tw-gap-4"
      @submit="submit"
    >
      <EntityContactsFormPartial
        ref="entityContacts"
        v-bind="entityParams"
        class="tw-col-span-2"
        @goBack="goBack"
      />
      <div class="tw-flex tw-flex-row tw-gap-4">
        <FormulateInput
          type="submit"
          label="Opslaan"
          :disabled="isLoading"
          :input-class="['tw-w-full']"
          :outer-class="['tw-w-full sm:tw-w-1/2']"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]"
          /> Opslaan
        </FormulateInput>
        <FormulateInput
          type="button"
          :input-class="['tw-bg-error tw-w-full']"
          :outer-class="['tw-w-full sm:tw-w-1/2']"
          @click="goBack"
        >
          <i class="fa fa-times tw-mr-2"/> Annuleren
        </FormulateInput>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import EntityContactsFormPartial from '@/components/properties/EntityContactsFormPartial'

export default {
  name: 'EntityContactsForm',
  components: {
    EntityContactsFormPartial
  },
  data () {
    return {
      values: {}
    }
  },
  computed: {
    entityId () {
      return this.$route.params.id
    },
    isProperty () {
      return this.$route.meta.entity_type === 'property'
    },
    backRoute () {
      return this.$route.meta.backRoute
    },
    entityParams () {
      return {
        contactRole: this.role,
        formValues: this.values,
        [this.isProperty ? 'propertyId' : 'projectId']: this.entityId
      }
    },
    role () {
      return this.$route.meta.role
    },
    displayRole () {
      const roleMap = {
        owner: 'Eigenaars',
        buyer: 'Kopers',
        renter: 'Huurders'
      }
      return roleMap[this.role]
    }
  },
  methods: {
    goBack () {
      this.$router.push(this.backRoute)
    },
    async submit () {
      const response = await this.$refs.entityContacts.submit()
      return response
    }
  }
}
</script>
